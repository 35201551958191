import React, { Component,useEffect } from "react";
import ps_images from "./ps_images"
import { TwitchEmbed, TwitchChat, TwitchClip, TwitchPlayer } from 'react-twitch-embed';
import ChatContainer from "./ChatContainer"
import Draggable from 'react-draggable'; 
import YouTube from 'react-youtube';
import Notification from "./Notification";
import Letter from "./Letter";
import WaterWave from 'react-water-wave';
import Background from "./background.jpeg";
import Countdown from 'react-countdown';
import './App.css';
import { render } from "@testing-library/react";
import io from "socket.io-client";
import Input from './components/Input/Input';
import GetnoofUsers from "./components/Getnoofusers";
import {socket} from "./components/services/socket"; 
// const ENDPOINT = "http://localhost:5000";

class StreamVideo extends Component {

    constructor(props) {
      super(props);
      
      this.state={
        users: [],
        toggleCloseCaptions: false
      }
    }


  renderMarqueeDrag = (id, text) => {
    return (
      <Draggable>
      <div 
          id={id}
          className="marquee_component">
          <marquee>
          <h1>
            {text}  
          </h1> 
          </marquee>
      </div>
      </Draggable>
    )
  };


  renderStreamVideo = () => {

    let style_iframe_container = {
      padding: "56.25% 0 0 0",
      position: "relative"
    }

    let style_iframe = {
      position: "absolute",
      top: "0",
      left:"0",
      width:"100%",
      height:"100%",
      maxHeight: "75vh"
    }

    return (
      <div className="stream_video_main">
      <div className="banner_logo">
        <img 
          src="https://res.cloudinary.com/dlq4uayvr/image/upload/v1621997295/assets/Performance_Space_New_York_logo-02_jmdcru.jpg" 
        />
      </div>
      <div className="stream_video_main_inner">
      <section className="iframe_container">
          <div 
            style={style_iframe_container}>
            <iframe 
              style={style_iframe}
              src="https://vimeo.com/event/1009342/embed" 
              frameBorder="0" 
              allow="autoplay; fullscreen; picture-in-picture" 
              allowFullScreen 
            />
        </div>  
          {this.renderMobileDonate()}
      </section>
      </div> 
      </div>
    )
  };

  renderMobileDonate = () => {
    return (
      <div className="mobile_donate">
          <marquee>
          To donate text "Performance" to 44321
          </marquee>
      </div>
    )
  }





  toggleCloseCaptions = () => {
    this.setState({
      toggleCloseCaptions: !this.state.toggleCloseCaptions
    })
  }

  renderDynamicStyling = () => {
    if(this.state.toggleCloseCaptions){
      return "60%"
    }else{
      return "inherit"
    }
  }


  renderCloseCaptions = () => {
    if(this.state.toggleCloseCaptions){
      return (
        <div className="close_caption">
          <iframe src="https://east.typewell.com/aehbbdba" />
        </div>
      )
    }else{
      return null;
    }
  }


  renderChatContainer = () => {
    return (
        <div className="chat_container">
          <div className="top_background_2">
            <div className="donate_marquee">
               <marquee>
               To donate text "Performance" to 44321
               </marquee>
            </div>
            <div className="close_caption_link">
                <span onClick={this.toggleCloseCaptions}>   
                  Live Captions. Click Here.
                </span>
                <a 
                  href="https://east.typewell.com/aehbbdba" 
                  target="_blank" 
                  rel="noreferrer"
                >
                <span className="caption_link_arrow">
                  ↗️
                </span>
                </a>
            </div>
          </div>
          <div className="iframe_chat_container">
          {this.renderCloseCaptions()}
            <iframe
              style={{height: this.renderDynamicStyling()}}
              className="iframe_2"
              src="https://vimeo.com/event/1009342/chat/" width="400" height="600" frameborder="0" ></iframe
            >
          </div>
        </div>
    )
};



  render(){
    return (
      <main className="stream_container">
        {this.renderStreamVideo()}
        {this.renderChatContainer()}
      </main>
    );
  }

}

export default StreamVideo;