const ps_images = module.exports = {
    backgroundImg: [
        "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1487101159/water_PNG3317_tsc2jc.png",
        "https://res.cloudinary.com/dlq4uayvr/image/upload/v1618359665/assets/Blackhole-Animation-Concept_vglba2.gif",
    
    ],
    movingImg: [
        "https://res.cloudinary.com/dlq4uayvr/image/upload/v1621215943/assets/42-420820_heart-rose-transparent-frame-transparent-background-heart-frame_bzscux.png",

    ],
    performersImg: [
        "https://res.cloudinary.com/dlq4uayvr/image/upload/v1620152881/performers/Screen_Shot_2021-04-19_at_2.23.17_PM_ldyvsn.png",
        "https://res.cloudinary.com/dlq4uayvr/image/upload/v1620152872/performers/DebbieHarry1_dlggsy.jpg",
        "https://res.cloudinary.com/dlq4uayvr/image/upload/v1620152835/performers/c0db9538-69a3-4c23-91c1-30bd5303afde_a28jl0.gif",
        "https://res.cloudinary.com/dlq4uayvr/image/upload/v1620152833/performers/image-1_yflujt.jpg",
        "https://res.cloudinary.com/dlq4uayvr/image/upload/v1620152829/performers/debbiekermit_dr58up.jpg",
        "https://res.cloudinary.com/dlq4uayvr/image/upload/v1620152827/performers/51cB2vCmp_L._AC_SY741__bocep5.jpg",
        "https://res.cloudinary.com/dlq4uayvr/image/upload/v1620152826/performers/image_dtqg5p.jpg",
        "https://res.cloudinary.com/dlq4uayvr/image/upload/v1620152823/performers/annie-sprinkle-the-tongue_mlmijq.jpg",
        "https://res.cloudinary.com/dlq4uayvr/image/upload/v1620152821/performers/SprinkleStephens500-1_hm3zz0.jpg",
        "https://res.cloudinary.com/dlq4uayvr/image/upload/v1620152820/performers/HannahBlackImage_dtyqzl.jpg",
        "https://res.cloudinary.com/dlq4uayvr/image/upload/v1620152819/performers/89dd465baa638860b21397ed48740f9f_XL_eco9wg.jpg",
        "https://res.cloudinary.com/dlq4uayvr/image/upload/v1620152816/performers/annie-and-beth1170_xlk8lh.jpg",
        "https://res.cloudinary.com/dlq4uayvr/image/upload/v1620152814/performers/performers_ent0a1.jpg",
        "https://res.cloudinary.com/dlq4uayvr/image/upload/v1620152804/performers/id-bell-yasuko-yokoshi-down_1000_x4n0gv.jpg"
    ],
    icons: [
        "https://res.cloudinary.com/dlq4uayvr/image/upload/v1621215943/assets/42-420820_heart-rose-transparent-frame-transparent-background-heart-frame_bzscux.png",
        "https://res.cloudinary.com/dlq4uayvr/image/upload/v1621215936/assets/unnamed-1_wm7vq5.png",
        "https://res.cloudinary.com/dlq4uayvr/image/upload/v1618359711/assets/5_kqukqu.png",
        "https://res.cloudinary.com/dlq4uayvr/image/upload/v1621215979/assets/580b585b2edbce24c47b2714_ggqgyu.png",
        "https://res.cloudinary.com/dlq4uayvr/image/upload/v1621215952/assets/6907926_preview_igdfhr.png",
        "https://res.cloudinary.com/dlq4uayvr/image/upload/v1621215938/assets/44-440881_sphere-clipart-green-3d-ball-png_jb2ve5.png",
        "https://res.cloudinary.com/dlq4uayvr/image/upload/v1621215952/assets/6907926_preview_igdfhr.png",
        "https://res.cloudinary.com/dlq4uayvr/image/upload/v1621215938/assets/44-440881_sphere-clipart-green-3d-ball-png_jb2ve5.png",
        "https://res.cloudinary.com/dlq4uayvr/image/upload/v1621215929/assets/3d-transparent-heart-png-picture-pngmafia-3d-heart-png-600_399_iolgd4.png",
        "https://res.cloudinary.com/dlq4uayvr/image/upload/v1618359783/assets/8_nwjchf.png"
    ]
};   
