import React, { Component } from "react";
import Draggable from 'react-draggable'; 
import YouTube from 'react-youtube';
import Letter from "./Letter";
import WaterWave from 'react-water-wave';
import Background from "./background.jpeg";
import Countdown from 'react-countdown';
import './App.css';
import { render } from "@testing-library/react";
import StreamVideo from "./StreamVideo";
import ChatContainer from "./ChatContainer";


class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isDisplayedStream: false,
      isOpenCocktail: false,
      showText: true,
      isDisplayedChat: false,
    };
  }

  // toggle functions;
  toggleStreamSection = () => {
    this.setState({
      isDisplayedStream: !this.state.isDisplayedStream
    })
  }

  renderFirstold = () => {

    const {
      isDisplayedStream
    } = this.state;

    if(isDisplayedStream){
      return null;
    }

    return (
      <div className="first_fold">
            <WaterWave
              style={{
                width: "100%",
                height: "100vh",
                backgroundSize: "cover",
                background: `url(${Background}) no-repeat center center fixed`
              }}
              dropRadius={50}
              perturbance={0.05}
              interactive={true}
            >
            </WaterWave>
      </div>
    )
  };

  renderCountDown = () => {
    return (
      <div className="timestamp">
      <Countdown
        renderer={this.toggleGala}
        id="countdown_animate" 
        date={1622073600000}>
          {this.renderStreamCTA()}
        </Countdown>  
      </div>
    )
  };

  // date={1621882800000}


  renderOverlayText = () => {  

    const {
      isDisplayedStream
    } = this.state;

    if(isDisplayedStream){
      return null;
    }

    return (
      <div className="overlay_text">
          <h1> 
              PERFORMANCE SPACE NEW YORK <br />
              40TH ANNIVERSARY GALA 
              <div>
                 {this.renderCountDown()}
              </div>
          </h1>
      </div>
    )
  }


  renderStreamCTA = () => {
      return (
        <div className="stream_cta">
          <h2 onClick={this.toggleStreamSection}>
              ENTER HERE
          </h2>
        </div>
      )
  }

  
  renderStreamVideo = () => {  
   const {
     isDisplayedStream
   } = this.state;

   if(!isDisplayedStream){
     return null;
   }
    return (
      <StreamVideo />
    )
  };



  render(){
    return (
      <main className="App">
        {this.renderStreamVideo()}
        {this.renderFirstold()}
        {this.renderOverlayText()}
      </main>
    );
  }

}

export default App;