import React from "react";
import logo from './logo.svg';
import styled, {keyframes} from 'styled-components';
import './App.css';


function Letter(props) {
  const {
    index,
    data
  } = props;

  const fadeIn = keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  `;

  const Div = styled.div`
   
  `;

  // animation: ${fadeIn} 2s;
  // animation-delay: ${index*0.3}s;


  if(data === " "){
    return (
      <Div key={props.index}>
      <span>
        &nbsp; 
      </span>
    </Div>
    )
  }else{
    return (
      <Div key={props.index}>
        <span>
          {props.data}
        </span>
      </Div>
    );
  }
}

export default Letter;
