import React from "react";
import logo from './logo.svg';
import styled, {keyframes} from 'styled-components';
import './App.css';


function Notification(props) {

  const { text, author, color, img} = props.data;
  let index = props.index;

  const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

  const Div = styled.div`
    animation: ${fadeIn} ${index*10}s;
  `;

  let displayImg = img
  .map((ele, index) => {
    return (
      <div className="notification_avatar_container">
         <img src={ele} />
      </div>
    )
  })

  return (
    <Div 
     className="notification_inner">
      {displayImg}
      <span
        style={{color: color}}>
        {author}
      </span>
      <h2>
        {text}
      </h2>
    </Div>
  );
}

export default Notification;
