import React from 'react';
import ReactDOM from 'react-dom';
import Favicon from 'react-favicon'
import './index.css';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <Favicon url='https://res.cloudinary.com/dlq4uayvr/image/upload/v1618359660/assets/PERFSPACE_LOGO_lrzawg.png' />
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
